var data = {
    "0": {
        "param_order": [
            "0", 
            "1", 
            "2", 
            "3", 
            "4", 
            "5", 
            "6", 
            "7", 
            "8", 
            "9", 
            "10", 
            "11", 
            "12", 
            "13", 
            "14", 
            "15", 
            "16", 
            "17", 
            "18", 
            "19", 
            "20", 
            "21", 
            "22", 
            "23", 
            "24", 
            "25", 
            "28", 
            "29", 
            "30", 
            "31", 
            "32", 
            "33", 
            "34", 
            "35", 
            "36", 
            "37", 
            "38", 
            "39", 
            "40", 
            "41", 
            "42", 
            "43", 
            "48", 
            "49", 
            "50", 
            "58"
        ], 
        "parameters": {
            "0": {
                "name": {
                    "label": "durum", 
                    "translation": {
                        "en": "status", 
                        "tr": "durum"
                    }, 
                    "value": "0"
                }, 
                "options": [
                    {
                        "label": "pasif", 
                        "translation": {
                            "en": "passive", 
                            "tr": "pasif"
                        }, 
                        "value": "0"
                    }, 
                    {
                        "label": "aktif", 
                        "translation": {
                            "en": "active", 
                            "tr": "aktif"
                        }, 
                        "value": "1"
                    }
                ], 
                "show": "yes", 
                "type": "select"
            }, 
            "1": {
                "name": {
                    "label": "adı", 
                    "translation": {
                        "en": "pro name", 
                        "tr": "adı"
                    }, 
                    "value": "1"
                }, 
                "show": "yes", 
                "type": "text"
            }, 
            "10": {
                "name": {
                    "label": "adet son ek", 
                    "translation": {
                        "en": "pro count suffix", 
                        "tr": "adet son ek"
                    }, 
                    "value": "10"
                }, 
                "show": "yes", 
                "type": "text"
            }, 
            "11": {
                "name": {
                    "label": "ödeme gecikme maksimum gün", 
                    "translation": {
                        "en": "pay delay max days", 
                        "tr": "ödeme gecikme maksimum gün"
                    }, 
                    "value": "11"
                }, 
                "show": "yes", 
                "type": "integer"
            }, 
            "12": {
                "name": {
                    "label": "organizasyon id", 
                    "translation": {
                        "en": "organization id", 
                        "tr": "organizasyon id"
                    }, 
                    "value": "12"
                }, 
                "show": "yes", 
                "type": "text"
            }, 
            "13": {
                "name": {
                    "label": "organizasyon adı", 
                    "translation": {
                        "en": "organization name", 
                        "tr": "organizasyon adı"
                    }, 
                    "value": "13"
                }, 
                "show": "yes", 
                "type": "text"
            }, 
            "14": {
                "name": {
                    "label": "organizasyon kullanıcı id", 
                    "translation": {
                        "en": "organization user id", 
                        "tr": "organizasyon kullanıcı id"
                    }, 
                    "value": "14"
                }, 
                "show": "yes", 
                "type": "text"
            }, 
            "15": {
                "name": {
                    "label": "organizasyon kullanıcı adı", 
                    "translation": {
                        "en": "organization username", 
                        "tr": "organizasyon kullanıcı adı"
                    }, 
                    "value": "15"
                }, 
                "show": "yes", 
                "type": "text"
            }, 
            "16": {
                "name": {
                    "label": "hazırlama tipi", 
                    "translation": {
                        "en": "preparation type", 
                        "tr": "hazırlama tipi"
                    }, 
                    "value": "16"
                }, 
                "options": [
                    {
                        "label": "hazır", 
                        "translation": {
                            "en": "ready", 
                            "tr": "hazır"
                        }, 
                        "value": "0"
                    }, 
                    {
                        "label": "hazırlanacak", 
                        "translation": {
                            "en": "to be prepared", 
                            "tr": "hazırlanacak"
                        }, 
                        "value": "1"
                    }
                ], 
                "show": "yes", 
                "type": "select"
            }, 
            "17": {
                "name": {
                    "label": "organizasyon görev tipi", 
                    "translation": {
                        "en": "task type", 
                        "tr": "organizasyon görev tipi"
                    }, 
                    "value": "17"
                }, 
                "options": [
                    {
                        "label": "bilgilendirme", 
                        "translation": {
                            "en": "to inform", 
                            "tr": "bilgilendirme"
                        }, 
                        "value": "0"
                    }, 
                    {
                        "label": "fatura", 
                        "translation": {
                            "en": "invoice", 
                            "tr": "fatura"
                        }, 
                        "value": "1"
                    }, 
                    {
                        "label": "genel", 
                        "translation": {
                            "en": "general", 
                            "tr": "genel"
                        }, 
                        "value": "2"
                    }, 
                    {
                        "label": "yazılım tasarımı yapma", 
                        "translation": {
                            "en": "software design", 
                            "tr": "yazılım tasarımı yapma"
                        }, 
                        "value": "3"
                    }
                ], 
                "show": "yes", 
                "type": "select"
            }, 
            "18": {
                "name": {
                    "label": "organizasyon önceliği", 
                    "translation": {
                        "en": "priority", 
                        "tr": "organizasyon önceliği"
                    }, 
                    "value": "18"
                }, 
                "options": [
                    {
                        "label": "yok", 
                        "translation": {
                            "en": "none", 
                            "tr": "yok"
                        }, 
                        "value": "0"
                    }, 
                    {
                        "label": "düşük", 
                        "translation": {
                            "en": "low", 
                            "tr": "düşük"
                        }, 
                        "value": "1"
                    }, 
                    {
                        "label": "orta", 
                        "translation": {
                            "en": "middle", 
                            "tr": "orta"
                        }, 
                        "value": "2"
                    }, 
                    {
                        "label": "yüksek", 
                        "translation": {
                            "en": "high", 
                            "tr": "yüksek"
                        }, 
                        "value": "3"
                    }, 
                    {
                        "label": "acil", 
                        "translation": {
                            "en": "urgent", 
                            "tr": "acil"
                        }, 
                        "value": "4"
                    }
                ], 
                "show": "yes", 
                "type": "select"
            }, 
            "19": {
                "name": {
                    "label": "ödeme tipi", 
                    "translation": {
                        "en": "pay type", 
                        "tr": "ödeme tipi"
                    }, 
                    "value": "19"
                }, 
                "options": [
                    {
                        "label": "tek sefer", 
                        "translation": {
                            "en": "one time", 
                            "tr": "tek sefer"
                        }, 
                        "value": "0"
                    }, 
                    {
                        "label": "periyodik", 
                        "translation": {
                            "en": "periodic", 
                            "tr": "periyodik"
                        }, 
                        "value": "1"
                    }
                ], 
                "show": "yes", 
                "type": "select"
            }, 
            "2": {
                "name": {
                    "label": "client id", 
                    "translation": {
                        "en": "pro client id", 
                        "tr": "client id"
                    }, 
                    "value": "2"
                }, 
                "show": "yes", 
                "type": "text"
            }, 
            "20": {
                "name": {
                    "label": "ödeme period tipi", 
                    "translation": {
                        "en": "pay period type", 
                        "tr": "ödeme period tipi"
                    }, 
                    "value": "20"
                }, 
                "options": [
                    {
                        "label": "ayda bir", 
                        "translation": {
                            "en": "once in a month", 
                            "tr": "ayda bir"
                        }, 
                        "value": "0"
                    }, 
                    {
                        "label": "yılda bir", 
                        "translation": {
                            "en": "once a year", 
                            "tr": "yılda bir"
                        }, 
                        "value": "1"
                    }
                ], 
                "show": "yes", 
                "type": "select"
            }, 
            "21": {
                "name": {
                    "label": "ödeme kredi kartı ile", 
                    "translation": {
                        "en": "pay credit card", 
                        "tr": "ödeme kredi kartı ile"
                    }, 
                    "value": "21"
                }, 
                "options": [
                    {
                        "label": "hayır", 
                        "translation": {
                            "en": "no", 
                            "tr": "hayır"
                        }, 
                        "value": "0"
                    }, 
                    {
                        "label": "evet", 
                        "translation": {
                            "en": "yes", 
                            "tr": "evet"
                        }, 
                        "value": "1"
                    }
                ], 
                "show": "yes", 
                "type": "select"
            }, 
            "22": {
                "name": {
                    "label": "ödeme hesaba transfer ile", 
                    "translation": {
                        "en": "pay transfer to account", 
                        "tr": "ödeme hesaba transfer ile"
                    }, 
                    "value": "22"
                }, 
                "options": [
                    {
                        "label": "hayır", 
                        "translation": {
                            "en": "no", 
                            "tr": "hayır"
                        }, 
                        "value": "0"
                    }, 
                    {
                        "label": "evet", 
                        "translation": {
                            "en": "yes", 
                            "tr": "evet"
                        }, 
                        "value": "1"
                    }
                ], 
                "show": "yes", 
                "type": "select"
            }, 
            "23": {
                "name": {
                    "label": "kullanım adedi tipi", 
                    "translation": {
                        "en": "pro usage count type", 
                        "tr": "kullanım adedi tipi"
                    }, 
                    "value": "23"
                }, 
                "options": [
                    {
                        "label": "kullanım adedi yok", 
                        "translation": {
                            "en": "no usage", 
                            "tr": "kullanım adedi yok"
                        }, 
                        "value": "0"
                    }, 
                    {
                        "label": "kullanım adedine resetle", 
                        "translation": {
                            "en": "reset to usage", 
                            "tr": "kullanım adedine resetle"
                        }, 
                        "value": "1"
                    }, 
                    {
                        "label": "üzerine ekle", 
                        "translation": {
                            "en": "add on", 
                            "tr": "üzerine ekle"
                        }, 
                        "value": "2"
                    }, 
                    {
                        "label": "belirtilen kullanım adedinde bir fiyatlandır", 
                        "translation": {
                            "en": "price for the specified number of uses", 
                            "tr": "belirtilen kullanım adedinde bir fiyatlandır"
                        }, 
                        "value": "3"
                    }
                ], 
                "show": "yes", 
                "type": "select"
            }, 
            "24": {
                "name": {
                    "label": "para birimi tipi", 
                    "translation": {
                        "en": "currency type", 
                        "tr": "para birimi tipi"
                    }, 
                    "value": "24"
                }, 
                "options": [
                    {
                        "label": "para birimi bazlı", 
                        "translation": {
                            "en": "currency based", 
                            "tr": "para birimi bazlı"
                        }, 
                        "value": "0"
                    }
                ], 
                "show": "yes", 
                "type": "select"
            }, 
            "25": {
                "name": {
                    "label": "döviz para değeri", 
                    "translation": {
                        "en": "currency amount", 
                        "tr": "döviz para değeri"
                    }, 
                    "value": "25"
                }, 
                "param_order": [
                    "26", 
                    "27"
                ], 
                "parameters": {
                    "26": {
                        "name": {
                            "label": "döviz tipi", 
                            "translation": {
                                "en": "cur type", 
                                "tr": "döviz tipi"
                            }, 
                            "value": "26"
                        }, 
                        "options": [
                            {
                                "label": "tl", 
                                "translation": {
                                    "en": "tl", 
                                    "tr": "tl"
                                }, 
                                "value": "0"
                            }, 
                            {
                                "label": "dolar", 
                                "translation": {
                                    "en": "dollar", 
                                    "tr": "dolar"
                                }, 
                                "value": "1"
                            }
                        ], 
                        "show": "yes", 
                        "type": "select"
                    }, 
                    "27": {
                        "name": {
                            "label": "tutar", 
                            "translation": {
                                "en": "cur amount", 
                                "tr": "tutar"
                            }, 
                            "value": "27"
                        }, 
                        "show": "yes", 
                        "type": "float"
                    }
                }, 
                "show": "yes", 
                "type": "list_parameter"
            }, 
            "28": {
                "name": {
                    "label": "vergi oranı yüzdesi", 
                    "translation": {
                        "en": "tax rate percent", 
                        "tr": "vergi oranı yüzdesi"
                    }, 
                    "value": "28"
                }, 
                "show": "yes", 
                "type": "float"
            }, 
            "29": {
                "name": {
                    "label": "paket ürün listesinde görünme durumu", 
                    "translation": {
                        "en": "show in bundle product list", 
                        "tr": "paket ürün listesinde görünme durumu"
                    }, 
                    "value": "29"
                }, 
                "options": [
                    {
                        "label": "hayır", 
                        "translation": {
                            "en": "no", 
                            "tr": "hayır"
                        }, 
                        "value": "0"
                    }, 
                    {
                        "label": "evet", 
                        "translation": {
                            "en": "yes", 
                            "tr": "evet"
                        }, 
                        "value": "1"
                    }
                ], 
                "show": "yes", 
                "type": "select"
            }, 
            "3": {
                "name": {
                    "label": "atama yapabilir mi", 
                    "translation": {
                        "en": "can assign auth", 
                        "tr": "atama yapabilir mi"
                    }, 
                    "value": "3"
                }, 
                "options": [
                    {
                        "label": "hayır", 
                        "translation": {
                            "en": "no", 
                            "tr": "hayır"
                        }, 
                        "value": "0"
                    }, 
                    {
                        "label": "evet", 
                        "translation": {
                            "en": "yes", 
                            "tr": "evet"
                        }, 
                        "value": "1"
                    }
                ], 
                "show": "yes", 
                "type": "select"
            }, 
            "30": {
                "name": {
                    "label": "sona erme süre tipi", 
                    "translation": {
                        "en": "expiration time type", 
                        "tr": "sona erme süre tipi"
                    }, 
                    "value": "30"
                }, 
                "options": [
                    {
                        "label": "gün", 
                        "translation": {
                            "en": "day", 
                            "tr": "gün"
                        }, 
                        "value": "0"
                    }, 
                    {
                        "label": "ay", 
                        "translation": {
                            "en": "month", 
                            "tr": "ay"
                        }, 
                        "value": "1"
                    }, 
                    {
                        "label": "yıl", 
                        "translation": {
                            "en": "year", 
                            "tr": "yıl"
                        }, 
                        "value": "2"
                    }
                ], 
                "show": "yes", 
                "type": "select"
            }, 
            "31": {
                "name": {
                    "label": "sona erme süre değeri", 
                    "translation": {
                        "en": "expiration time value", 
                        "tr": "sona erme süre değeri"
                    }, 
                    "value": "31"
                }, 
                "show": "yes", 
                "type": "integer"
            }, 
            "32": {
                "name": {
                    "label": "sona erme süre değeri yazı ile", 
                    "translation": {
                        "en": "expiration time value by text", 
                        "tr": "sona erme süre değeri yazı ile"
                    }, 
                    "value": "32"
                }, 
                "show": "yes", 
                "type": "text"
            }, 
            "33": {
                "name": {
                    "label": "fiyat vergiler dahil durumu", 
                    "translation": {
                        "en": "price includes taxes", 
                        "tr": "fiyat vergiler dahil durumu"
                    }, 
                    "value": "33"
                }, 
                "options": [
                    {
                        "label": "vergiler dahil", 
                        "translation": {
                            "en": "taxes included", 
                            "tr": "vergiler dahil"
                        }, 
                        "value": "0"
                    }, 
                    {
                        "label": "vergiler hariç", 
                        "translation": {
                            "en": "excluding taxes", 
                            "tr": "vergiler hariç"
                        }, 
                        "value": "1"
                    }
                ], 
                "show": "yes", 
                "type": "select"
            }, 
            "34": {
                "name": {
                    "label": "organizasyon token", 
                    "translation": {
                        "en": "organization token", 
                        "tr": "organizasyon token"
                    }, 
                    "value": "34"
                }, 
                "show": "yes", 
                "type": "textarea"
            }, 
            "35": {
                "name": {
                    "label": "mağaza client id", 
                    "translation": {
                        "en": "shop client id", 
                        "tr": "mağaza client id"
                    }, 
                    "value": "35"
                }, 
                "show": "yes", 
                "type": "text"
            }, 
            "36": {
                "name": {
                    "label": "ürün tipi", 
                    "translation": {
                        "en": "product type", 
                        "tr": "ürün tipi"
                    }, 
                    "value": "36"
                }, 
                "options": [
                    {
                        "label": "genel", 
                        "translation": {
                            "en": "general", 
                            "tr": "genel"
                        }, 
                        "value": "0"
                    }, 
                    {
                        "label": "çözüm", 
                        "translation": {
                            "en": "solution", 
                            "tr": "çözüm"
                        }, 
                        "value": "1"
                    }, 
                    {
                        "label": "platform", 
                        "translation": {
                            "en": "platform", 
                            "tr": "platform"
                        }, 
                        "value": "2"
                    }, 
                    {
                        "label": "kod şablonu", 
                        "translation": {
                            "en": "code template", 
                            "tr": "kod şablonu"
                        }, 
                        "value": "3"
                    }, 
                    {
                        "label": "wanalyzer istatistik görüntüle", 
                        "translation": {
                            "en": "wanalyzer stats view", 
                            "tr": "wanalyzer istatistik görüntüle"
                        }, 
                        "value": "4"
                    }, 
                    {
                        "label": "wanalyzer dataset filtrele", 
                        "translation": {
                            "en": "wanalyzer dataset filter", 
                            "tr": "wanalyzer dataset filtrele"
                        }, 
                        "value": "5"
                    }, 
                    {
                        "label": "wanalyzer dataset istatistik sorgula", 
                        "translation": {
                            "en": "wanalyzer dataset stats query", 
                            "tr": "wanalyzer dataset istatistik sorgula"
                        }, 
                        "value": "6"
                    }, 
                    {
                        "label": "wanalyzer makine öğrenmesi sorgula", 
                        "translation": {
                            "en": "wanalyzer machine learning query", 
                            "tr": "wanalyzer makine öğrenmesi sorgula"
                        }, 
                        "value": "7"
                    }, 
                    {
                        "label": "wanalyzer sorgu oluşturma", 
                        "translation": {
                            "en": "wanalyzer create query", 
                            "tr": "wanalyzer sorgu oluşturma"
                        }, 
                        "value": "8"
                    }
                ], 
                "show": "yes", 
                "type": "select"
            }, 
            "37": {
                "name": {
                    "label": "wanalyzer proje id", 
                    "translation": {
                        "en": "wanalyzer project id", 
                        "tr": "wanalyzer proje id"
                    }, 
                    "value": "37"
                }, 
                "show": "yes", 
                "type": "text"
            }, 
            "38": {
                "name": {
                    "label": "wanalyzer istatistik id", 
                    "translation": {
                        "en": "wanalyzer stats id", 
                        "tr": "wanalyzer istatistik id"
                    }, 
                    "value": "38"
                }, 
                "show": "yes", 
                "type": "text"
            }, 
            "39": {
                "name": {
                    "label": "wanalyzer makine öğrenmesi id", 
                    "translation": {
                        "en": "wanalyzer machine learning id", 
                        "tr": "wanalyzer makine öğrenmesi id"
                    }, 
                    "value": "39"
                }, 
                "show": "yes", 
                "type": "text"
            }, 
            "4": {
                "name": {
                    "label": "yetki kodu", 
                    "translation": {
                        "en": "pro auth id", 
                        "tr": "yetki kodu"
                    }, 
                    "value": "4"
                }, 
                "show": "yes", 
                "type": "text"
            }, 
            "40": {
                "name": {
                    "label": "ürün kodu", 
                    "translation": {
                        "en": "product code", 
                        "tr": "ürün kodu"
                    }, 
                    "value": "40"
                }, 
                "show": "yes", 
                "type": "text"
            }, 
            "41": {
                "name": {
                    "label": "katman ağacı", 
                    "translation": {
                        "en": "layer tree", 
                        "tr": "katman ağacı"
                    }, 
                    "value": "41"
                }, 
                "show": "yes", 
                "type": "text"
            }, 
            "42": {
                "name": {
                    "label": "katman kullanım tipi", 
                    "translation": {
                        "en": "layer usage type", 
                        "tr": "katman kullanım tipi"
                    }, 
                    "value": "42"
                }, 
                "options": [
                    {
                        "label": "hepsi", 
                        "translation": {
                            "en": "all", 
                            "tr": "hepsi"
                        }, 
                        "value": "0"
                    }, 
                    {
                        "label": "seçili", 
                        "translation": {
                            "en": "selected", 
                            "tr": "seçili"
                        }, 
                        "value": "1"
                    }
                ], 
                "show": "yes", 
                "type": "select"
            }, 
            "43": {
                "name": {
                    "label": "seçilen katmanlar", 
                    "translation": {
                        "en": "selected layers", 
                        "tr": "seçilen katmanlar"
                    }, 
                    "value": "43"
                }, 
                "param_order": [
                    "44", 
                    "45", 
                    "46", 
                    "47"
                ], 
                "parameters": {
                    "44": {
                        "name": {
                            "label": "seçilen katman id", 
                            "translation": {
                                "en": "selected layer id", 
                                "tr": "seçilen katman id"
                            }, 
                            "value": "44"
                        }, 
                        "show": "yes", 
                        "type": "text"
                    }, 
                    "45": {
                        "name": {
                            "label": "seçilen katman adı", 
                            "translation": {
                                "en": "selected layer name", 
                                "tr": "seçilen katman adı"
                            }, 
                            "value": "45"
                        }, 
                        "show": "yes", 
                        "type": "text"
                    }, 
                    "46": {
                        "name": {
                            "label": "parametre kullanım tipi", 
                            "translation": {
                                "en": "parameter usage type", 
                                "tr": "parametre kullanım tipi"
                            }, 
                            "value": "46"
                        }, 
                        "options": [
                            {
                                "label": "hepsi", 
                                "translation": {
                                    "en": "all", 
                                    "tr": "hepsi"
                                }, 
                                "value": "0"
                            }, 
                            {
                                "label": "seçili", 
                                "translation": {
                                    "en": "selected", 
                                    "tr": "seçili"
                                }, 
                                "value": "1"
                            }
                        ], 
                        "show": "yes", 
                        "type": "select"
                    }, 
                    "47": {
                        "name": {
                            "label": "seçilen parametreler", 
                            "translation": {
                                "en": "selected parameters", 
                                "tr": "seçilen parametreler"
                            }, 
                            "value": "47"
                        }, 
                        "show": "yes", 
                        "type": "text"
                    }
                }, 
                "show": "yes", 
                "type": "list_parameter"
            }, 
            "48": {
                "name": {
                    "label": "ana kural algoritması", 
                    "translation": {
                        "en": "main rule algorithm", 
                        "tr": "ana kural algoritması"
                    }, 
                    "value": "48"
                }, 
                "show": "yes", 
                "type": "text"
            }, 
            "49": {
                "name": {
                    "label": "lokasyon kullanım tipi", 
                    "translation": {
                        "en": "location usage type", 
                        "tr": "lokasyon kullanım tipi"
                    }, 
                    "value": "49"
                }, 
                "options": [
                    {
                        "label": "hepsi", 
                        "translation": {
                            "en": "all", 
                            "tr": "hepsi"
                        }, 
                        "value": "0"
                    }, 
                    {
                        "label": "seçili", 
                        "translation": {
                            "en": "selected", 
                            "tr": "seçili"
                        }, 
                        "value": "1"
                    }
                ], 
                "show": "yes", 
                "type": "select"
            }, 
            "5": {
                "name": {
                    "label": "hakkında", 
                    "translation": {
                        "en": "pro info", 
                        "tr": "hakkında"
                    }, 
                    "value": "5"
                }, 
                "show": "yes", 
                "type": "textarea"
            }, 
            "50": {
                "name": {
                    "label": "seçilen lokasyonlar", 
                    "translation": {
                        "en": "selected locations", 
                        "tr": "seçilen lokasyonlar"
                    }, 
                    "value": "50"
                }, 
                "param_order": [
                    "51", 
                    "52", 
                    "53", 
                    "54"
                ], 
                "parameters": {
                    "51": {
                        "name": {
                            "label": "lokasyon id", 
                            "translation": {
                                "en": "location id", 
                                "tr": "lokasyon id"
                            }, 
                            "value": "51"
                        }, 
                        "show": "yes", 
                        "type": "text"
                    }, 
                    "52": {
                        "name": {
                            "label": "lokasyon adı", 
                            "translation": {
                                "en": "location name", 
                                "tr": "lokasyon adı"
                            }, 
                            "value": "52"
                        }, 
                        "show": "yes", 
                        "type": "text"
                    }, 
                    "53": {
                        "name": {
                            "label": "sunucu kullanım tipi", 
                            "translation": {
                                "en": "server usage type", 
                                "tr": "sunucu kullanım tipi"
                            }, 
                            "value": "53"
                        }, 
                        "options": [
                            {
                                "label": "hepsi", 
                                "translation": {
                                    "en": "all", 
                                    "tr": "hepsi"
                                }, 
                                "value": "0"
                            }, 
                            {
                                "label": "seçili", 
                                "translation": {
                                    "en": "selected", 
                                    "tr": "seçili"
                                }, 
                                "value": "1"
                            }
                        ], 
                        "show": "yes", 
                        "type": "select"
                    }, 
                    "54": {
                        "name": {
                            "label": "seçilen sunucular", 
                            "translation": {
                                "en": "selected servers", 
                                "tr": "seçilen sunucular"
                            }, 
                            "value": "54"
                        }, 
                        "param_order": [
                            "55", 
                            "56", 
                            "57"
                        ], 
                        "parameters": {
                            "55": {
                                "name": {
                                    "label": "sunucu id", 
                                    "translation": {
                                        "en": "server id", 
                                        "tr": "sunucu id"
                                    }, 
                                    "value": "55"
                                }, 
                                "show": "yes", 
                                "type": "text"
                            }, 
                            "56": {
                                "name": {
                                    "label": "sunucu adı", 
                                    "translation": {
                                        "en": "server name", 
                                        "tr": "sunucu adı"
                                    }, 
                                    "value": "56"
                                }, 
                                "show": "yes", 
                                "type": "text"
                            }, 
                            "57": {
                                "name": {
                                    "label": "görünen sunucu adı", 
                                    "translation": {
                                        "en": "showed server name", 
                                        "tr": "görünen sunucu adı"
                                    }, 
                                    "value": "57"
                                }, 
                                "show": "yes", 
                                "type": "text"
                            }
                        }, 
                        "show": "yes", 
                        "type": "list_parameter"
                    }
                }, 
                "show": "yes", 
                "type": "list_parameter"
            }, 
            "58": {
                "name": {
                    "label": "satır limit adedi", 
                    "translation": {
                        "en": "row limit count", 
                        "tr": "satır limit adedi"
                    }, 
                    "value": "58"
                }, 
                "show": "yes", 
                "type": "text"
            }, 
            "6": {
                "name": {
                    "label": "başlama tarihi", 
                    "translation": {
                        "en": "pro start date", 
                        "tr": "başlama tarihi"
                    }, 
                    "value": "6"
                }, 
                "show": "yes", 
                "type": "datetime"
            }, 
            "7": {
                "name": {
                    "label": "bitiş tarihi", 
                    "translation": {
                        "en": "pro end date", 
                        "tr": "bitiş tarihi"
                    }, 
                    "value": "7"
                }, 
                "show": "yes", 
                "type": "datetime"
            }, 
            "8": {
                "name": {
                    "label": "grup adı", 
                    "translation": {
                        "en": "pro group name", 
                        "tr": "grup adı"
                    }, 
                    "value": "8"
                }, 
                "show": "yes", 
                "type": "text"
            }, 
            "9": {
                "name": {
                    "label": "kullanım adedi", 
                    "translation": {
                        "en": "pro usage count", 
                        "tr": "kullanım adedi"
                    }, 
                    "value": "9"
                }, 
                "show": "yes", 
                "type": "integer"
            }
        }
    }, 
    "about": [], 
    "bmi_gfr": "no", 
    "calculate_eval": "no", 
    "checked_on_open": "no", 
    "date_ability": "yes", 
    "date_ability_limit": "none", 
    "date_ability_type": "date", 
    "date_ability_user_selection": "no", 
    "document_upload": "no", 
    "ending_date_ability": "yes", 
    "ending_date_ability_limit": "none", 
    "ending_date_ability_type": "date", 
    "ending_date_ability_user_selection": "no", 
    "favorite_show_mode": "yes", 
    "language": {
        "label": "Türkçe", 
        "value": "tr"
    }, 
    "model": "wisdom_data", 
    "module": [], 
    "name": {
        "label": "user / shop / bundle / product", 
        "translation": {
            "en": "user shop bundle product", 
            "tr": "user shop bundle product"
        }, 
        "value": "L15"
    }, 
    "owner": {
        "label": "katman", 
        "value": "layer"
    }, 
    "owner_type": "layer", 
    "param_group": {
        "param_order": [
            "0"
        ], 
        "parameters": {
            "0": {
                "label": "Param", 
                "value": "0"
            }
        }
    }, 
    "parent": "L15", 
    "record_count": "many_times", 
    "status": "published", 
    "style": {
        "caption": {
            "after": "", 
            "backgroundColor": "white", 
            "border": {
                "color": "black", 
                "size": 0, 
                "type": "solid"
            }, 
            "borderRadius": 0, 
            "color": "black", 
            "fontSize": 12
        }, 
        "value": {
            "after": "", 
            "backgroundColor": "white", 
            "border": {
                "color": "black", 
                "size": 0, 
                "type": "solid"
            }, 
            "borderRadius": 0, 
            "color": "black", 
            "fontSize": 10
        }
    }, 
    "value": "L15", 
    "version": "1.0"
};
export { data }
